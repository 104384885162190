.projectsSection {
  font-family: "Montserrat-Medium";
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-top: 0.1rem solid #097179;
  padding: 15%;
}

.sectionHeader {
  padding: 0;
  padding-left: 5%;
  padding-right: 5%;
  margin-bottom: 15%;
  text-align: center;
  background: linear-gradient(#e8986b, #e8986b) bottom right/110% 45%;
  background-position: left 100% top 100%;
  background-repeat: no-repeat;
  font-size: 80px;
}
.sectionHeader h1 {
  margin: 0;
  font-family: "Montserrat-Medium";
  color: black;
  font-size: 80px;
}

.projectsCardsContainer {
  display: flex;
  gap: 1rem;
  min-height: 600px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
 
}

.about {
  text-align: center;
  background: linear-gradient(#e8986b, #e8986b) bottom right/100% 45%;
  background-position: left 100% top 100%;
  background-repeat: no-repeat;
  
}
