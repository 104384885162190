.contactSectionContainer {
  font-family: "Montserrat-Medium";
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  border-top: 0.1rem solid #097179;
  padding: 15% 15% 5% 15%;
}

.sectionHeader {
    text-align: center;
    background: linear-gradient(#e8986b, #e8986b) bottom right/100% 45%;
    background-position: left 100% top 100%;
    background-repeat: no-repeat;
    margin-bottom: 15%;
}

.contactSectionContainer h1 {
  font-family: "Montserrat-Medium";
  color: black;
  font-size: 80px;
}
