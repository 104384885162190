body {
  background: rgba(240,244,209,255);
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

@font-face {
  font-family: "LatoItalic";
  src: local("LatoItalic"),
    url("./Assets/Fonts/Lato-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "LatoLightItalic";
  src: local("LatoLightItalic"),
    url("./Assets/Fonts/Lato-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-Medium";
  src: local("Montserrat-Medium"),
    url("./Assets/Fonts/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Lato-Black";
  src: local("Lato-Black"),
    url("./Assets/Fonts/Lato-Black.ttf") format("truetype");
}

@font-face {
  font-family: "LatoRegular";
  src: local("LatoRegular"),
    url("./Assets/Fonts/Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "MontserratBlack";
  src: local("MontserratBlack"),
    url("./Assets/Fonts/Montserrat-Black.ttf") format("truetype");
}