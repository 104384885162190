.techCard {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    height: 100px;
    width: 80px;
    align-items: center;
    justify-content: center;

}
