
.progressBar {
  position: fixed;
  top: 49px;
  left: 0;
  right: 0;
  background: #097179;
  height: 10px;
  transform-origin: 0%;
  z-index: 4;
}
