.aboutSectionContainer {
  font-family: "Montserrat-Medium";
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-top: 0.1rem solid #097179;
  padding: 5%;
  padding-bottom: 15%;
}
.lineGraphic1 {
  width: 40vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sectionHeader {
  padding: 0;
  margin: 0;
  background: transparent;
  padding: 15%;
}
.sectionHeader h1 {
  margin: 0;
  font-family: "Montserrat-Medium";
  color: black;
  font-size: 80px;
  width: 500px;
}
.skills {
  text-align: center;
  background: linear-gradient(#e8986b, #e8986b) bottom right/100% 45%;
  background-position: left 100% top 100%;
  background-repeat: no-repeat;
}
.Experience {
  background: linear-gradient(#e8986b, #e8986b) center/67% 45%;
  background-position: left 50% top 100%;
  background-repeat: no-repeat;
  text-align: center;
}
.about {
  text-align: center;
  background: linear-gradient(#e8986b, #e8986b) bottom right/100% 45%;
  background-position: left 100% top 100%;
  background-repeat: no-repeat;
  
}


.skillsSection {
  display: flex;
  padding: 1rem;
  background-color: white;
  border-radius: 5px;
}

.graphichWithResume {
  display: flex;
  align-items: center;
  gap: 3rem;
  padding: 0 15% 10% 15%;

}
.graphichWithResume img {
  width: 350px;
  padding: 0;
  
}
.graphichWithResume h2 {
  font-weight: 500;
  font-size: 40px;
  width: 500px;
  margin-top: 0;
  padding: 0;
}

.aboutSectionText {
  max-width: 800px;
  font-size: 25px;
  line-height: 37px;
  padding: 0;
  margin: 0;
  transform: translateX(-50px);
}

.lineGraphicSpan {
    font-size: 2.5em;
    height: 350px;
    min-width: 500px;
    padding-bottom: 15px;
  
}

@media screen and (width < 950px) { 

  
  .graphichWithResume h2 {
    display: none;
  }
  
  .aboutSectionText {
    max-width: 400px;
    font-size: 15px;
    line-height: 37px;
  
  }
}
@media screen and (width < 500px) {
  .skillsSection {
    width: auto;
  }
  .biography {
    width: 95%;
  }
  .lineGraphic1 {
    width: 150px;
    
  }
  .lineGraphicSpan {
    min-width: none;
    font-size: 1.5em;
  }
  
  /* .sectionHeader {
    font-size: 1em;
  } */
  .sectionHeader h1 {
   
    font-size: 40px;
  }

  .skillsSection {
transform: translateX(-25px);
  }
  
}
