
.landingSection {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  align-items: center;
  justify-content: center;
  min-height: fit-content;
  padding-top: 5%;
  padding-bottom: 5%;
  
}
.graphic {
  transform: translate(50px, 150px);
}
.orangeHighlight {
  position: absolute;
  width: 500px;
  height: 60px;
  background-color: #e8986b;
  transform: translate(85px, 55px);

}
.LogoStuff {
  position: relative;
  display: grid;
  grid-template-columns: repeat(8, 100px);
  font-size: 70px;
  line-height: 110px;
}
.LogoStuff span {
  font-size: 300px;
  line-height: 220px;
}

.landingSectionText {
  display: flex;
  flex-direction: column;
  color: #097179;
  
}

.landingSectionText h1 {
  
  font-family: "Montserrat-Medium";
  font-weight: 900;
  margin: 0;
  margin-bottom: 5%;

}

.landingSectionText h2 {
  font-family: "LatoRegular";
  color: black;
  font-weight: 100;
  font-size: 4rem;
  align-items: left;
  margin: 0;
  transform: translate(110px, -10px);
  

}
.buttonBar {
  position: relative;
  display: flex;
  justify-content: left;
  transform: translate(110px, -10px);
 
}
.buttonBar button {
  border-style: hidden;
  background: transparent;
  position: relative;
}
.buttonBar button:hover {
  background-color: white;
  cursor: pointer;
  background: transparent;
}
.buttonBar button::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #097179;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 250ms;
}
.buttonBar button:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}
@media screen and (width < 1075px) {
  .LogoStuff {
    font-size: 35px;
    line-height: 55px;
    grid-template-columns: repeat(8, 50px);

  }
  .LogoStuff span {
    font-size: 150px;
    line-height: 110px;
  }
  .landingSectionText h2 {
    font-weight: 100;
    font-size: 2rem;
  }
  .buttonBar, .landingSectionText h2 {
    transform: translate(55px, -10px);
  }
  .buttonBar img{
    height: 25px;
    width: 25px;
  }
  .graphic {
    height: 225px;
    width: 475px;
    transform: translate(50px, 50px);
  }
  .orangeHighlight {
    width: 250px;
    height: 30px;
    transform: translate(42.5px, 27.5px);
  
  }
}

@media screen and (width < 500px) { 
  .graphic{
    height: 200px;
    width: 300px;
  }
  .landingSectionText h2 {
    font-weight: 100;
    font-size: 1rem;
  } 
  .landingSectionText h1 {
    
    
    font-size: 3rem;
    
  
  }
}