.logo {
  font-family: "Montserrat-Medium";
  font-style: italic;
  font-weight: 600;
  font-size: 2rem;
  color: #097179;
  white-space: nowrap;
}
.logo span {
  color: #e8986b;
}
.pulloutMenu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 4rem;
  font-family: "Montserrat-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 2em;
  color: #097179;
  height: 100vh;
  text-align: right;
  padding: 2rem;
  position: fixed;
  top: 0;
  right: 0;
  transition: transform 0.3s ease-in-out;
  background: white;
  z-index: 3;
  border-left: 0.1rem solid #097179;
  transform: translateY(0);
}
.pulloutMenuClosed {
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 4rem;
  font-family: "Montserrat-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 2em;
  color: #097179;
  height: 100vh;
  text-align: right;
  padding: 2rem;
  position: fixed;
  top: 0;
  right: 0;
  transition: transform 0.5s ease-in-out;
  background: white;
  z-index: 3;
  border-right: 0.1rem solid #097179;
  transform: translateY(-119vh);
}

.pulloutMenu ul,
.pulloutMenu ul li,
.pulloutMenuClosed ul li{
  list-style: none;
  position: relative;
}
nav {
  display: flex;
  justify-content: space-between;
  height: 3em;
  align-items: center;
  position: sticky;
  top: 0;
  border-bottom: 0.1rem solid #097179;
  background-color: white;
  padding-left: 5%;
  padding-right: 5%;
  z-index: 4;
}

nav ul {
  display: flex;
  list-style: none;
  align-items: center;
  padding: 0;
  gap: 5vh;
}

nav ul li {
  font-family: "Montserrat-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  white-space: nowrap;
  position: relative;
  color: #097179;
}
nav ul li::after,
.pulloutMenu ul li::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: currentColor;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 250ms;
}

nav ul li:hover::after,
.pulloutMenu ul li:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

nav ul li:hover,
.pulloutMenu ul li:hover {
  cursor: pointer;
}

nav ul a:visited,
nav ul a:default,
.pulloutMenu ul a:visited,
.pulloutMenu ul a:default,
.pulloutMenuClosed ul a:visited {
  color: #097179;
}

nav ul a,
.pulloutMenu ul a {
  text-decoration: none;
}
